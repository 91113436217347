@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Product Sans';
  src: url('./fonts/ProductSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Product Sans';
  src: url('./fonts/ProductSans-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Product Sans';
  src: url('./fonts/ProductSans-Regular.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Product Sans';
  src: url('./fonts/ProductSans-Regular.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Product Sans';
  src: url('./fonts/ProductSans-Regular.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Product Sans';
  src: url('./fonts/ProductSans-Regular.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
.loader {
  border-top-color: #3498db;
  animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
html {
  scroll-behavior: smooth;
}